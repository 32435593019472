import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ItemType, ItemGroup, GSTGroup, HSNSAC, Item } from '../../shared/model/index';
import { SharedDataService } from './shareddata.service';
import { User } from '../model/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvAccSetting } from '../model/invaccsetting.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  public issuereceivetype = null;
  public suid = null;
  public issuereceiveid=null;
  public schoolId: number;
  public currentUserId = (<User>this.loginService.currentUserSubject.value ).userId;
  
  constructor(private loginService: LoginService, private httpClient: HttpClient,
    public sharedData: SharedDataService) {
    this.loginService.currentUserSubject.subscribe(m=>{
      this.schoolId = m?m.schoolId:0;
    })
  }
  public getItemTypeList = function( ) {    
    return this.httpClient.get(apiUrl + '/api/Inventory/GetItemTypeList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
  };
  public manageItemType = function (itemtype: ItemType) {
    itemtype.userId = this.loginService.currentUserSubject.value.userId;
    itemtype.schId=this.schoolId;
    return this.httpClient.post(apiUrl + '/api/Inventory/ManageItemType', itemtype, httpOptions);
  
}
public getItemGroupList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemGroupList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageItemGroup = function (itemgroup: ItemGroup) {
  itemgroup.userId = this.loginService.currentUserSubject.value.userId;
  itemgroup.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageItemGroup', itemgroup, httpOptions);

}
public getGSTGroupList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetGSTGroupList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public manageGSTGroup = function (gstgroup: GSTGroup) {
  gstgroup.userId = this.loginService.currentUserSubject.value.userId;
  gstgroup.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageGSTGroup', gstgroup, httpOptions);

}
public getHSNSACList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetHSNSACList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public ManageHSNSAC = function (hsnsac: HSNSAC) {
  hsnsac.userId = this.loginService.currentUserSubject.value.userId;
  hsnsac.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageHSNSAC', hsnsac, httpOptions);

}
public getItemList = function( ) {    
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemList?schId=' + this.schoolId.toString()+'&mode='+1, httpOptions);
};
public ManageItem = function (item: Item) {
  item.userId = this.loginService.currentUserSubject.value.userId;
  item.schId=this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/ManageItem', item, httpOptions);

}
// public GetItemOpening = function(enddate){
//   return this.httpClient.get(apiUrl + '/api/Inventory/GetItemOpeningStock?enddate=' + enddate + '&schId=' + this.schoolId.toString() + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
// };
public GetItemStock = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};
public GetItemStockItemJournal = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);
};
public GetItemStockItemJournalList = function(enddate,entrytype,externaldoc){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemStock?enddate=' + enddate + '&schId=' + this.schoolId.toString()+ '&entrytype=' + entrytype + '&externaldoc=' + externaldoc + '&userid=' + this.currentUserId + '&mode=' + 3, httpOptions);
};
// public SaveItemOpeningStock = function(ItemViewWithDetail: any) {
//   ItemViewWithDetail.userId = this.loginService.currentUserSubject.value.userId;
//   ItemViewWithDetail.SchoolId = this.schoolId;
//   return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemOpeningStock', ItemViewWithDetail, httpOptions);
// };
public SaveItemStock = function(ItemViewWithDetail: any) {
  ItemViewWithDetail.userId = this.loginService.currentUserSubject.value.userId;
  ItemViewWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemStock', ItemViewWithDetail, httpOptions);
};
public getInvAccSettingList = function(): Observable<any> {
  return this.httpClient.get(apiUrl + '/api/Inventory/GetInvAccSettingList?schid=' + this.schoolId.toString())
  .pipe(map((scList: any) => {
   this.sharedData.invaccsettingList = scList ;
   return scList;
  }));
};
public manageInvAccSetting = function (invaccsetting: InvAccSetting) {
  // school.userId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post(apiUrl + '/api/Inventory/AddInvAccSetting', invaccsetting, httpOptions);
};
public GetItemFavourite = function(stdid){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemFavourite?schId=' + this.schoolId.toString()+ '&stdid=' + stdid + '&userid=' + this.currentUserId + '&mode=' + 1, httpOptions);
};

public SaveItemFavourite = function(ItemFavouriteViewWithDetail: any) {
  ItemFavouriteViewWithDetail.UserId = this.loginService.currentUserSubject.value.userId;
  ItemFavouriteViewWithDetail.SchoolId = this.schoolId;
  return this.httpClient.post(apiUrl + '/api/Inventory/SaveItemFavourite', ItemFavouriteViewWithDetail, httpOptions);
};

public GetItemFavouriteForStudent = function(stdid){
  return this.httpClient.get(apiUrl + '/api/Inventory/GetItemFavourite?schId=' + this.schoolId.toString()+ '&stdid=' + stdid + '&userid=' + this.currentUserId + '&mode=' + 2, httpOptions);
};

getPDF(domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Inventory/PrintStudentIssueReceivePDF?qry='+ this.issuereceivetype +'&stId='+ this.suid 
  +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  +'&userid='+ this.currentUserId, {  responseType: 'blob' as 'json', httpOptions });
}

Mail(domainName){
  return this.httpClient.post(apiUrl + '/api/Inventory/GetItemIssueReceiveMailDetail?qry='+ this.issuereceivetype +'&issuereceiveid='+ this.issuereceiveid +'&stId='+ this.suid
  +'&schId='+ this.schoolId.toString() +'&userid='+ this.currentUserId + '&domain=' + domainName, httpOptions );
}

public getStudentItemIssueReceiptList = function (fromDate,toDate, sessionId) {
  return this.httpClient.get(apiUrl + '/api/inventory/GetStudentItemIssueReceiptList?schid=' + this.schoolId.toString() +
    '&stSession=' + sessionId + '&fromdate=' + fromDate + '&todate=' + toDate, httpOptions);
};
getPDFstatus(qry,domainName){
  return this.httpClient.post<Blob>(apiUrl + '/api/Inventory/PrintStudentIssueReceiveStatusPDF?qry='+ qry +'&stId='+ this.suid 
  +'&schId='+ this.schoolId.toString() +'&domain='+domainName 
  +'&userid='+ this.currentUserId, {  responseType: 'blob' as 'json', httpOptions });
}

}
